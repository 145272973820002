* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Mono', monospace;
  color: white
}

@keyframes growEffect {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.vertical-timeline-element-icon {
  cursor: pointer;
  &:hover {
    animation: growEffect 0.2s ease-in-out forwards !important;
    transform-origin: 50% 100%;
  }
}

.last-vte-custom-parent .vertical-timeline-element-icon{
  cursor: auto;
  animation: none !important;
  transform-origin: initial !important;
  transform: translate(-9.5px, -45px);
  @media only screen and (max-width: 1169px) {
    /* Styles for screens smaller than 1169 pixels */
    transform: translate(-20px, -25px);
  }
}

/* SWIPER PAGINATION */

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 12px)) !important;
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 12px)) !important;
  background: var(--swiper-pagination-bullet-inactive-color, white) !important;
}

.swiper-pagination-bullet-active {
  background-color: #249e67 !important;
  width: 16px !important;
  height: 16px !important;
  box-shadow: 0 0 8px yellow;
  transform: translateY(2px);
}

.swiper-slide-shadow-left {
  border-radius: 20px !important;
}

.swiper-slide-shadow-right {
  border-radius: 20px !important;
}